<template>
  <div class="box" @scroll="scrollSetData">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item v-for="item in bannerImgList" :key="item.id">
        <img :src="item.url" class="imagess">
      </el-carousel-item>
    </el-carousel>
    <!-- 导航 -->
    <div class="content">
      <div class="content_01">
        <span v-for="item in dataList" @click="tebBtn(item.id)" :class="['p1', { 'p2': item.id == id }]"
          :key="item.id">{{
              item.name
          }}</span>
      </div>
    </div>
    <div class="contentData">
      <div style="margin: 20px;" v-for="item in navData" :key="item.id">
        <div class="elCard">
          <div class="camera">
            <div class="camera_img">
              <img :src="item.cameraUrl" alt="">
            </div>
            <div class="camera_text">
              <h2>{{ item.cameraName }}</h2>
              <p>{{ item.cameraTitle }}</p>
            </div>
          </div>
          <div class="imgList">
            <div class="imgList_top">
              <el-card class="card">
                <h2>{{ item.mobile_top }}</h2>
                <li v-if="item.liData != '' && item.list2Data == null">{{ item.liData }}</li>
                <p style="display: flex;" v-if="item.list2Data != null">
                  <li>{{ item.list2Data.list_1 }}</li>
                  <li style="margin-left: 8rem;">{{ item.list2Data.list_2 }}</li>
                </p>
              </el-card>
              <div class="card_bottom" :class="{ card_bottom_1: item.liData != '' || item.list2Data != null }">
                <!-- Technical Advantages -->
                <div class="box" v-for="e in item.mobileList" :key="e.id">
                  <p v-if="item.liData == '' && item.list2Data == null">{{ e.name }}</p>
                  <img :class="{ igs: item.isHeight }" :src="e.url" alt="">
                </div>
              </div>
            </div>
            <div class="imgList_bottom" :class="{ imgList_bottom_1: item.mobile_title == 'Notebook computer.' }">
              <el-card class="card">
                <h2>{{ item.mobile_bottom }}</h2>
                <p>{{ item.mobile_title }}</p>
              </el-card>
              <div class="box_1">
                <div class="box_01" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists == null">
                  <img class="img_2" :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <div class="box_02" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists != null">
                  <img :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <img :src="item.imgs" img_11alt=""
                  :class="[{ img_11: item.imgs != '' }, { img_22: item.mobile_title == 'Notebook computer.' || item.Veh }]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p element-loading-text="Loading, Please Wait..." v-loading="loading" style="height: 5.5rem;"></p>
      <p v-if="loading" class="loading"></p>
      <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);">Loaded</p>
      <!-- <p class="data_p" v-if="id!=5">没有数据</p> -->
      <!-- <div v-else style="width: 100%;height:300px">111</div> -->
    </div>
    <!-- 底部 -->
    <foot @submitData="submitData"></foot>

  </div>
  <div class="_div">
    <!--  小屏-->
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in bannerImgList" :key="item.id">
        <img :src="item.url" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <el-scrollbar class="content_top">
      <div class="content_top_01"></div>
      <p v-for="item in dataList_move" @click="tebBtn(item.id)" :key="item.id" class="content_top_1"
        :class="{ p1: item.id == id }">{{
            item.name
        }}
      </p>
    </el-scrollbar>

    <div class="content_move" v-for="item in navData" :key="item.id">
      <div class="box_top">
        {{ item.name }}
        <div class="box_top_1"></div>
      </div>
      <div class="box_bottom">
        <div class="img_left">
          <img :src="item.cameraUrl_move" alt="">
          <h2>Product Explanation</h2>
          <p>{{ item.cameraTitle }}</p>
        </div>
        <div class="img_right">
          <h2>Technical Advantages</h2>
          <p>{{ item.liData }}</p>
          <img v-for="e in item.mobileList_move" :key="e.id" :src="e.url" alt="" v-if="item.mobileList_move != null"
            style="width: 35%;">
          <img :src="item.mobileList[0].url" alt="" v-if="item.mobileList_move == null" style="width: 50rem;">
          <h2 style="margin-top: 7rem;">Application Scenarios</h2>
          <p>{{ item.mobile_title }}</p>
          <img :src="item.imgLists[0].url" alt="" v-if="!item.isIMG" style="width: auto;height: 22rem;"
            :class="{ che: item.Veh == true }">
          <!-- <div class="IMG_box" v-if="item.isIMG">
            <img class="isIMGData" :src="i.url" alt=""  v-for="i in item.imgLists" :key="i.id"/>
          </div>   -->
          <img style="width: auto;height:17rem;margin-top: 3rem;" :src="i.url" alt="" v-for="i in item.imgLists"
            :key="i.id" v-if="item.isIMG" />
        </div>
      </div>
    </div>
    <p element-loading-text="Loading, Please Wait..." v-loading="loading" style="height: 13rem;"></p>
    <p v-if="loading" class="loading"></p>
    <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);margin: 5 rem 0;">Loaded</p>
    <!-- 底部 -->
    <move_foot @submitData="submitData"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import { reactive, toRefs, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import move_header from "../move_components/move_header.vue";
import move_foot from "../move_components/move_foot.vue";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const noMore = ref(false)
    const route = useRouter();
    const isRefreshBool = ref(true)
    const el_card = ref()
    const state = reactive({
      loading: false,
      id: localStorage.getItem('id') || 5,
      bannerImgList: [
        { id: 1, url: require('@/assets/homePage/en-product1.png') },
        { id: 2, url: require('@/assets/homePage/en-product2.png') },
        { id: 3, url: require('@/assets/homePage/en-product3.png') },
        { id: 4, url: require('@/assets/homePage/en-product4.png') },
        { id: 5, url: require('@/assets/homePage/en-product5.png') },
      ],
      dataList_move: [
        { id: 5, name: 'Mobile Termination', url: '', content: '' },
        { id: 4, name: 'Notebook', url: '', content: '' },
        { id: 3, name: 'AIoT', url: '', content: '' },
        { id: 2, name: 'Car & Medical', url: '', content: '' },
        { id: 1, name: 'LED', url: '', content: '' },
      ],
      dataList: [
        { id: 1, name: 'LED', url: '', content: '' },
        { id: 2, name: 'Car & Medical', url: '', content: '' },
        { id: 3, name: 'AIoT', url: '', content: '' },
        { id: 4, name: 'Notebook', url: '', content: '' },
        { id: 5, name: 'Mobile Termination', url: '', content: '' },
      ],
      navData: [],

      // LED数据
      LEDList: [{
        id: 1, isIMG: true, name: 'LED Module', liData: 'Fine local dimming.Ultrahigh contrast, and more pronounced color contrast.', cameraUrl_move: require('@/assets/product_center/LED/move_1.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/LED Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Cell phone, notebook computer, AR/VR glasses, etc.', mobile_bottom: 'Application Scenarios', cameraTitle: 'LED modules are used for backlighting and other applications by applying LEDs to PCBs and glass substrates through processes such as SMD or COB. It is used in TV, notebook computer, tablet PC, car, cell phone, VR/AR glasses and other scenarios.',
        list2Data: { list_1: 'Fine local dimming', list_2: 'Ultrahigh contrast and more pronounced color contrast.' },
        mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },
        { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/LED/3.png') },
        { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/LED/4.png') }],
        mobileList_move:
          [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 3, url: require('@/assets/product_center/LED/眼镜.png') },
        ]

      },
      {
        id: 2, isIMG: true, name: 'LED Logo Display Module', liData: 'FPC+LGF module+LED+connector, LED3.0*1.0*0.4mm, RGB, 20mA constant current driver.', cameraUrl_move: require('@/assets/product_center/LED/move_2.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/LED Logo Display Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Tablet, notebook computer, cell phones, etc.', mobile_bottom: 'Application Scenarios', cameraTitle: 'COG01T is applied to GAMING PHONE. The light is emitted by guiding the light from two LEDs into a surface light source through LGF (Light Guide Film).',
        list2Data: { list_1: 'FPC+LGF module+LED+connector', list_2: ' RGB, 20mA cross-current driver.' },
        mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
        { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/LED/7.png') },
        { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/LED/8.png') }],
        mobileList_move: [
          { id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
          { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        ],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 2, url: require('@/assets/product_center/LED/平板.png') },
        ]
      }],
      // NB数据
      NBList: [
        {
          id: 1, name: 'Ultra Narrow/Thin NB Camera', cameraUrl_move: require('@/assets/product_center/NB/move_1.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: 'FHD resolution (2M, 1080P), RGB sensor, OV02CRGB lens, and Powertip 4P AA lens.', cameraUrl: require('@/assets/product_center/NB/Ultra Narrow_Thin NB Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Notebook computer.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It is applied to thin and light notebook computers. The module is manufactured by COB process to provide 2MP effective output, and the camera is only 2.58mm in height and 3mm in width.',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
        {
          id: 2, name: 'NB Dual Camera', cameraUrl_move: require('@/assets/product_center/NB/move_2.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: 'AI biometric/anti-snooping support.', cameraUrl: require('@/assets/product_center/NB/NB Dual Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Notebook computer.', mobile_bottom: 'Application Scenarios', cameraTitle: ' 2M resolution (FHD) + 1M (HD), RGB sensor, OV02CU COB, RGB lens, PT 42091, IR sensor, HM1092U CSP, IR lens, Powertip 3P lens.',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/3.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
      ],
      // 车载
      vehicleList: [
        {
          id: 1, name: "Headrest Screen Camera Module-4K (32MP)", cameraUrl_move: require('@/assets/product_center/vehicle/move_1.png'), isHeight: false, Veh: true, imgs: '', liData: ' 360 degree around view monitor(AVM) forward-looking technology, and 32MP headrest screen camera of back seat.', cameraUrl: require('@/assets/product_center/vehicle/Headrest Screen Camera Module-4K (32MP).png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: `BYD Auto's Han series & Yuan series.`, mobile_bottom: 'Application Scenarios', cameraTitle: `It is applied to BYD Auto's Han series & Yuan series. The Han series uses forward-looking technology for 32MP headrest screen camera of back seat, and the Yuan series uses a surround view video camera.`,
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车1.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车2.png') },
          ],

        },
        {
          id: 2, name: 'DMS/DVR/360 Degree Panorama/OMS', cameraUrl_move: require('@/assets/product_center/vehicle/move_2.png'), isHeight: false, Veh: true, imgs: '', liData: `Clearer recording / larger recording range / more convenient storage / intelligent auto-detection / more natural images.`, cameraUrl: require('@/assets/product_center/vehicle/DMS_DVR_360 Degree Panorama_OMS.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: `FAW, Chang'an Auto, Dongfeng - CAIC.`, mobile_bottom: 'Application Scenarios', cameraTitle: `It is used in FAW, Chang'an Auto, Dongfeng - CAIC, etc. It has many benefits such as clearer records and more convenient storage with a large record range.`,
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车3.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车4.png') },
          ],
        }
      ],
      // 智能物联网
      AIoTList: [
        {
          id: 1, name: 'IP Camera', cameraUrl_move: require('@/assets/product_center/AIoT/move_1.png'), isHeight: false, imgs: '', liData: 'Supports USB 3.0 transfer protocol, 4K video output, and authentication for Windows Hello face recognition login, Teams, Lync, and other video conferencing.', cameraUrl: require('@/assets/product_center/AIoT/4K External Video Conference Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Notebook computer, desktop computer.', mobile_bottom: 'Application Scenarios', cameraTitle: 'The BW831C is an 8MP external camera with photo and video capabilities, designed for audiences with high-definition video conferencing, HD webcasting, and other needs.',
          list2Data: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/电脑的副本.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/电脑.png') },
          ],

        },
        {
          id: 2, name: 'Headrest Screen Camera Module-4K (32MP)', cameraUrl_move: require('@/assets/product_center/AIoT/move_2.png'), isHeight: false, imgs: '', liData: '3P lens, GC2063 sensor, GK7205 low power consumption and high performance SOC.', cameraUrl: require('@/assets/product_center/AIoT/IP Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: '3P lens, GC2063 sensor, GK7205 low power consumption and high performance SOC.', mobile_bottom: 'Application Scenarios', cameraTitle: '3P lens, GC2063 sensor, GK7205 low power consumption and high performance SOC, 1080P HDR video mode support.',
          list2Data: null,
          mobileList_move: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/4.png') }],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/5.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/6.png') },
          ],
        }
      ],
      // 超大广角这两个
      UltraList: [
        {
          id: 1, isHeight: false, name: 'Ultra-wide Angle Module', imgs: '', liData: 'The front camera is HD, pretty and dazzling with a large FOV, and the back camera assists optical zoom and increases the amount of light intake.', cameraUrl_move: require('@/assets/product_center/mobile/超大广角/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/超大广角/Ultra-wide Angle Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Cell phone.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It is a 16MP ultra-wide angle module, which increases the FOV of the lens to broaden the photographic view and improve the amount of light intake, and has features such as multi-fold zoom.',
          list2Data: null,
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
          { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/mobile/超大广角/超大广角3.png') },
          { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/mobile/超大广角/超大广角4.png') }],
          mobileList_move: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
          ],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
          ]
        },
        {
          id: 2, isHeight: false, name: 'Dual Camera Module', imgs: '', liData: 'Background blur, large aperture, topnotch night shots, and HDR.', cameraUrl_move: require('@/assets/product_center/mobile/双摄模组/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/双摄模组/Dual Camera Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Cell phone.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It is a 48MP focusable camera, the same as a 5MP fixed focus camera, which is put together by a metal bracket to form a combo camera. It can increase the amount of light intake, takes pictures with background bokeh and high dynamic range.',
          list2Data: null,
          mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
          { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
          { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/mobile/双摄模组/双摄模组3.png') },
          { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/mobile/双摄模组/双摄模组4.png') }],
          mobileList_move: [
            { id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
            { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
          ],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
          ]
        }
      ]
    })
    let methods = {
      tebBtn(id) {
        state.id = id
        localStorage.setItem('id', id)
        noMore.value = false
        if (state.id == 1) {
          state.navData = state.LEDList
        } else if (state.id == 2) {
          state.navData = state.vehicleList
        } else if (state.id == 3) {
          state.navData = state.AIoTList
        } else if (state.id == 4) {
          state.navData = state.NBList
        } else if (state.id == 5) {
          state.navData = state.UltraList
        }
        setTimeout(() => {
          methods.alternate()
        })

      },
      submitData(id) {
        methods.tebBtn(id)
        // console.log('id',id);
      },
      scrollSetData() {
        window.onscroll = () => {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件(距底部20px时触发加载)
          let Date = 1000
          if (
            scrollTop + windowHeight >= scrollHeight - 400
          ) {
            let id = localStorage.getItem('id') || 5
            let num = state.navData.length
            if (id == 5 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 3, name: 'OIS Anti-shake Camera Module', cameraUrl_move: require('@/assets/product_center/mobile/OIS光学防抖模组/move_1.png'), isHeight: false, imgs: '', liData: 'It can automatically compensate the shooting image by algorithm, effectively overcome the blurred image caused by vibration when shooting, and provid a clearer and smoother shooting experience.', cameraUrl: require('@/assets/product_center/mobile/OIS光学防抖模组/OIS Anti-shake Camera Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Handheld mobile photography, in-car photography, mobile videography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It can avoid the problem of blurred images caused by hand shake and external shake, to ensure the clarity of the picture.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/OIS光学防抖模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/OIS光学防抖模组/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ]
                  },
                  {
                    id: 12, name: 'G+P Large Area Sensor and OIS Camera', cameraUrl_move: require('@/assets/product_center/mobile/G+P大底OIS摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'It is the application of "G + P" lens technology, through the use of molded glass aspheric Llenses (1G + 5P) to improve the optical performance of the lens, and it helps to filter out stray light and reduce dispersion. The large area sensor achieves a more detailed and realistic photographic effect, along with OIS anti-shake technology, to reach a superb photographic experience.', cameraUrl: require('@/assets/product_center/mobile/G+P大底OIS摄像头/G+P Large Area Sensor and OIS Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Near and far scene cell phone photography, night photography, mobile videography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It combines a G+P lens, large area sensor and an OIS motor to produce high-quality images even in low light or night scenes.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },

                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 5 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 5, name: 'Microscope Camera Module', cameraUrl_move: require('@/assets/product_center/mobile/显微镜模组/move_1.png'), isHeight: false, imgs: require('@/assets/product_center/mobile/显微镜模组/4.png'), liData: 'It can shoot 3mm ultra-close objects, with cell phone algorithm can achieve 60X microscope effect. The microcosm can be explored through cell phone photography.', cameraUrl: require('@/assets/product_center/mobile/显微镜模组/Microscope Camera Module.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Ultra-macro photography, microscopic observation.', mobile_bottom: 'Application Scenarios', cameraTitle: 'The microscope camera module can apply cell phone algorithm to achieve 60X magnification function and realize microscope effect.',
                    list2Data: null,
                    mobileList_move: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/显微镜模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/显微镜模组/3.png') }],
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/显微镜模组/4.png') }
                    ],

                  },
                  {
                    id: 6, name: 'All-in-one Front Facing Camera', cameraUrl_move: require('@/assets/product_center/mobile/一体化前置摄像头/move_1.png'), isHeight: false, imgs: '', liData: `With small head diameter, it can reduce the size of the phone's exposed camera, improve the phone's screen-to-body ratio, and enhances the overall visual experience of the phone.`, cameraUrl: require('@/assets/product_center/mobile/一体化前置摄像头/All-in-one Front Facing Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Cell phone selfies.', mobile_bottom: 'Application Scenarios', cameraTitle: 'With small head diameter and high lens resolution, it is widely used in cell phone front-facing camera.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/一体化前置摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/一体化前置摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 7 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 7, name: 'High Pixel Autofocus Camera', cameraUrl_move: require('@/assets/product_center/mobile/高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'It enables autofocus function fastly and high pixel image capture at different distances, bringing the clearest images for shooting.', cameraUrl: require('@/assets/product_center/mobile/高像素AF摄像头/High Pixel Autofocus Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Near and far scene cell phone photography.', mobile_bottom: 'Application Scenarios', cameraTitle: ' It enables autofocus function and high pixel image capture at different distances for use as a main camera.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 8, name: '200MP Ultrahigh Pixel Camera with Autofocus', cameraUrl_move: require('@/assets/product_center/mobile/200M超高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'While satisfying autofocus, it can meet the extreme 200MP UHD image capture, with every detail still visible when magnified.', cameraUrl: require('@/assets/product_center/mobile/200M超高像素AF摄像头/200MP Ultrahigh Pixel Camera with Autofocus.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Near and far scene cell phone photography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It can shoot 200 megapixel UHD photos, which is the highest pixel in the industry.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 9 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 9, name: 'Periscopic Telephoto Camera', cameraUrl_move: require('@/assets/product_center/mobile/潜望式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'The periscopic telephoto camera has little difference in sharpness between the center and the edge of the image, allowing for a good balance of fine detail. The design of the periscope structure converts the height of the telephoto lens into width, allowing the phone to maintain an ultra-thin size.', cameraUrl: require('@/assets/product_center/mobile/潜望式长焦摄像头/Periscopic Telephoto Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Ultra-long distance cell phone photography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'The periscopic telephoto camera, which allows for a 5x optical zoom, maintains high definition when shooting ultra-distant objects.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 10, name: 'Retractable Telephoto Camera', cameraUrl_move: require('@/assets/product_center/mobile/伸缩式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: ' It extends the lens when working and retracts when not working, which can effectively reduce the size of the camera to meet the size of the phone, and the telescopic structure can be paired with a large area sensor to enhance the shooting effect.', cameraUrl: require('@/assets/product_center/mobile/伸缩式长焦摄像头/Retractable Telephoto Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Long distance cell phone photography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'Retractable Telephoto Camera achieves zooming by telescoping the lens to capture distant objects.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 11 && isRefreshBool.value) {

              isRefreshBool.value = false
              state.loading = true

              setTimeout(() => {
                state.navData.push(
                  {
                    id: 13, name: 'Wide Angle + Telephoto + High Pixel Triple Camera', cameraUrl_move: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/move_1.png'), isHeight: true, imgs: '', liData: 'Wide angle, telephoto, and high pixel cameras are combined together. The wide-angle and telephoto cameras assist the high pixel main camera to take pictures for clearer expression, while giving the camera more features to reduce the exposure of the picture quality and take better results.', cameraUrl: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/Wide Angle + Telephoto + High Pixel Triple Camera.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Multiple scene cell phone photography.', mobile_bottom: 'Application Scenarios', cameraTitle: 'With the combination of wide angle, telephoto and high pixel cameras, it covers the needs of each usage scenario of cell phone photography.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 14, name: 'Dual Camera Module with Depth of Field Calculation', cameraUrl_move: require('@/assets/product_center/mobile/景深计算双摄模块/move_1.png'), isHeight: false, imgs: '', liData: 'It mainly calculates the depth of field to achieve background bokeh and refocusing. It can adjust the aperture size to change the degree of photo bokeh, and re-call the depth of field information of the object in the photo to make it possible to take a picture first and then focus on it (refocusing function).', cameraUrl: require('@/assets/product_center/mobile/景深计算双摄模块/Dual Camera Module with Depth of Field Calculation.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Near and far scene cell phone photography, background blur.', mobile_bottom: 'Application Scenarios', cameraTitle: 'It is a combination of depth of field camera and main camera to achieve double focus function.',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/景深计算双摄模块/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/景深计算双摄模块/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            }
            if (id == 2 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 3, name: "DMS/OMS/DVR/ Camera modules for entertainment devices like VR, TV, vedio game, etc.", cameraUrl_move: require('@/assets/product_center/vehicle/move_3.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360° Around View 2M ADAS All-in-one Front View Camera 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组1-en.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'BYD, GAC, Volvo, etc.', mobile_bottom: 'Application Scenarios', cameraTitle: 'This product is an automatic driving assistance device used in intelligent cockpit, with 1M/2M 360° around view function. The mass production customers are BYD, GAC, Volvo, etc.',
                    list2Data: null,
                    isIMG: false,
                    mobileList: [
                      { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/5.png') },
                      { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/6.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/vehicle/车5.png') },
                      { id: 2, url: require('@/assets/product_center/vehicle/车6.png') },
                    ],

                  },
                  {
                    id: 4, name: 'DMS/OMS/DVR/ Camera modules for entertainment devices like VR, TV, vedio game, etc.', cameraUrl_move: require('@/assets/product_center/vehicle/move_4.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360° Around View 2M ADAS All-in-one Front View Camera 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组2-en.png'), cameraName: 'Product Explanation', mobile_top: 'Technical Advantages', mobile_title: 'Great Wall Motor, XPeng Motor, NIO, Toyota, etc.', mobile_bottom: 'Application Scenarios', cameraTitle: 'This product is a video input device supporting entertainment functions in the intelligent cockpit, which can support real-time image technology and has entertainment functions such as vedio games and TV, as well as video calls. The designated customers who are deeply cooperated are Great Wall Motor, XPeng Motor, NIO, Toyota, etc.',
                    list2Data: null,
                    isIMG: false,
                    mobileList: [
                      { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/7.png') },
                      { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/8.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/vehicle/车7.png') },
                      { id: 2, url: require('@/assets/product_center/vehicle/车8.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            }
          }
          methods.alternate()
        }
      },
      // 控制左右布局的代码
      alternate() {
        let elCard = document.getElementsByClassName('elCard')
        let box_top = document.getElementsByClassName('box_top')
        let box_top_1 = document.getElementsByClassName('box_top_1')
        let box_bottom = document.getElementsByClassName('box_bottom')
        setTimeout(() => {
          for (let i = 0; i < box_top.length; i++) {
            if (i % 2 == 0) {
              box_top[i].style.marginLeft = '0rem'
              box_top_1[i].style.left = ''
              box_bottom[i].style.flexDirection = 'row'
            } else {
              box_top[i].style.marginLeft = '20rem'
              box_top_1[i].style.left = '-15rem'
              box_bottom[i].style.flexDirection = 'row-reverse'
            }
          }
        })
        // console.log(elCard);
        for (let i = 0; i < elCard.length; i++) {
          if (i % 2 == 0) {
            elCard[i].style.flexDirection = 'row'
          } else {
            elCard[i].style.flexDirection = 'row-reverse'
          }
        }
      }
    }

    onMounted(() => {
      methods.scrollSetData()
      // methods.tebBtn(localStorage.getItem('id'))
      methods.alternate()
      if (state.id == 5) {
        state.navData = state.UltraList
      } else if (state.id == 4) {
        state.navData = state.NBList
      } else if (state.id == 3) {
        state.navData = state.AIoTList
      } else if (state.id == 2) {
        state.navData = state.vehicleList
      } else if (state.id == 1) {
        state.navData = state.LEDList
      }


    });
    return {
      ...toRefs(state), ...methods, el_card, noMore, isRefreshBool
    };
  },
};
</script>

<style lang="less" scoped>
.elCard {
  overflow: hidden;
  width: 100%;
  height: 950px;
  display: flex;
  background-color: #F5F5F5;
  border-radius: 15px;

  .camera {
    flex: 2;
    padding: 120px 0px 30px 0px;

    .camera_img {
      margin-left: 30px;
      // width: 70%;
      // height: 74%;

      img {
        width: 100%;
        height: 66%;
      }
    }

    .camera_text {
      margin: 0 auto;
      width: 70%;
      height: 40%;
      padding: 0 30px 0 0;
      box-sizing: border-box;
      margin-bottom: 20px;
      h2 {
        margin-top: -10px;
        font-size: 24px;
        text-align: center;
      }
      p {
        Letter-spacing: 2px;
        width: 320px;
      }
    }
  }

  .imgList {
    flex: 4;
    padding: 40px 30px 40px 30px;
    display: flex;
    flex-direction: column;

    .imgList_top,
    .imgList_bottom {
      flex: 1;
    }

    .imgList_top {
      position: relative;

      li {
        margin-top: 20px;
        text-align: left;
        padding-left: 0 30px;
        box-sizing: border-box;
      }
    }

    .imgList_bottom {
      position: relative;
      box-sizing: border-box;
      padding-top: 18rem;


      .box_1 {
        position: relative;
        bottom: 60px;
        width: 100%;
        height: 100%;
        // background-color: red;
        display: flex;
        margin-top: -40px;

        .box_01 {
          flex: 1;
          text-align: center;

          img {
            width: 80%;
            margin-top: 30px;
            height: auto;

          }
        }

        .box_02 {
          text-align: center;
          margin: 0 auto;
          position: relative;

          img:nth-child(1) {
            width: 70%;
            margin-top: 30px;
            margin-left: 23px;
          }
        }

        .img_11 {
          width: auto;
          height: 300px;
          margin-left: 500px;
        }

        .img_22 {
          position: absolute;
          width: auto;
          height: 200px;
          left: -220px;
          top: 30px;
        }

        // .img_2 {
        //   flex: 1;
        //   padding: 35px;
        //   box-sizing: border-box;
        //   width: 30%;
        //   height: 65%;
        // }
      }
    }

    .imgList_bottom_1 {
      padding-top: 3rem;
    }
  }

}

.data_p {
  text-align: center;
  font-size: 24px;
  color: #666;
}

.el-tooltit {
  max-width: 400px;
  line-height: 180%;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
  }

  .igs {
    padding: 3.6rem !important;
    margin-top: -3.8rem !important;
  }

  .loading {
    font-size: 24px;
    text-align: center;
  }

  .content {
    margin: 0 auto;
    max-width: 1920px;
    width: 90%;

    .content_01 {
      width: 100%;
      height: 100px;
      line-height: 100px;
      // background-color: red;
      float: right;
      margin-right: 11%;

      .p1 {
        // margin-right: 30px;
        display: block;
        float: right;
        cursor: pointer;
        height: 65px;
        width: auto;
        margin-right: 20px;
        text-align: center;
        overflow: hidden;
      }

      .p2 {
        border-bottom: 3px solid #ef3e2e;
      }
    }

  }



  .contentData {
    overflow: auto;
    margin: 0 auto;
    max-width: 1920px;
    width: 70%;
    overflow-y: hidden
  }
}

// 走马灯
.demonstration {
  color: var(--el-text-color-secondary);
}


::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.imagess {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .card {
  border-radius: 14px 14px 30px 30px;
  height: 180px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  margin-left: 4px;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
}

.card_bottom_1 {
  margin-top: 100px;
}

.card_bottom {
  position: absolute;
  top: 5.12rem;
  left: 10px;
  z-index: 9;
  width: 100%;
  height: 150px;
  display: flex;
  display: flex;
  box-sizing: border-box;

  .box {
    flex: 1;

    p {
      text-align: left;
      text-align: center;
      width: 180px;
      overflow: hidden;
      height: 70px;
    }

    img {
      margin: 0 auto;
      width: 95%;
      padding: 10px;
      margin-top: -30px;
      box-sizing: border-box;
    }

  }
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .che {
      height: 14rem !important;
    }

    .content_top {
      position: relative;
      padding: 30px;
      text-align: center;
      width: 80%;
      margin: 0 auto;
      line-height: 150px;
      white-space: nowrap;

      // overflow-y: hidden;
      // overflow-x: scroll;
      .content_top_01 {
        position: absolute;
        height: 70%;
        right: 0;
        width: 0px;
        box-shadow: -30px 0px 50px 30px #ccc;
      }

      p {
        display: inline-block;
        font-size: 60px;
        margin: 0;
        margin-right: 50px;

        cursor: pointer;
      }

      .p1 {
        border-bottom: 8px solid #ef3e2e;
      }
    }

    .content_move {
      padding: 30px;
      width: 96.8%;


      .box_top {
        position: relative;
        font-size: 60px;
        color: #fff;
        width: 80%;
        text-align: center;
        height: 150px;
        line-height: 150px;
        background-color: #5C5C5C;
        position: relative;

        // margin-left: 1050px;
        .box_top_1 {
          position: absolute;
          left: 1450px;
          top: 70px;
          width: 280px;
          height: 10px;
          background-color: #5C5C5C;
        }
      }


      .box_bottom {
        display: flex;

        h2 {
          font-size: 50px;
          margin: 30px 0 20px 0;
        }

        p {
          width: 90%;
          margin: 0 auto;
          text-align: left;
          font-size: 40px
        }

        .img_left {
          flex: 4;
          text-align: center;

          img {
            width: 80%;
            margin-top: 250px;
          }
        }

        .img_right {
          flex: 6;
          text-align: center;

          img {

            margin: 20px 40px 0;
          }
        }


      }
    }
  }
}
</style>